import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@fleet/auth';
import { Observable } from 'rxjs';
import { NetworkGroupService } from '@fleet/network-group';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TravellerGuestJobGuard {
  constructor(
    private authService: AuthService,
    private networkGroupService: NetworkGroupService,
    private productConfigurationService: ProductConfigurationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //clear any jwt

    this.authService.clearToken();

    // Wait for product configuration and then get network
    this.productConfigurationService.networkConfig$
      .pipe(
        filter((config) => !!config && !!config.networkId),
        take(1)
      )
      .subscribe((config) => {
        console.log('Network config loaded in guest job guard:', config);
        this.networkGroupService.getNetwork(config.networkId);
        if (config.networkGroupId) {
          this.networkGroupService.getNetworkGroup(
            config.networkGroupId,
            config.networkId
          );
        } else {
          this.networkGroupService.setSelectedGroup(null);
        }
      });

    return true;
  }
}
