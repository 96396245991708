<fleet-sidebar-form-layout
  *ngIf="mode === 'sidebar'; else dialog"
  class="flex-auto"
  [title]="
    paymentMethod
      ? 'Block Card'
      : blockAttribute
      ? 'Update Attribute'
      : 'Create Attribute'
  "
  (closeSidebar)="cancelled.emit()"
>
  <div *ngIf="form" class="flex flex-col flex-auto bg-default">
    <div class="mt-8 px-6">
      <ng-container *ngTemplateOutlet="formLayout"> </ng-container>
    </div>

    <div class="flex-grow"></div>

    <fuse-alert
      class="px-6 py-3"
      *ngFor="let alert of issues | alertsFromIssues"
      [appearance]="'outline'"
      [showIcon]="false"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <fleet-action-buttons>
      <button mat-button type="button" (click)="cancelled.emit()">
        Cancel
      </button>

      <fleet-progress-button
        (onClick)="submit()"
        class="ml-3"
        [state]="{
          loading: loading,
          buttonLabel: blockAttribute ? 'Update' : 'Create'
        }"
        [disabled]="!form.valid || loading"
        type="submit"
      >
      </fleet-progress-button>
    </fleet-action-buttons>
  </div>
</fleet-sidebar-form-layout>

<ng-template #dialog>
  <fleet-dialog-layout
    *ngIf="form"
    (cancelled)="cancelled.emit()"
    [title]="
      paymentMethod
        ? 'Block Card'
        : blockAttribute
        ? 'Update Attribute'
        : 'Create Attribute'
    "
  >
    <fuse-alert
      slot="error"
      *ngFor="let alert of issues | alertsFromIssues"
      [appearance]="'outline'"
      [showIcon]="true"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
    >
      {{ alert.message }}
    </fuse-alert>

    <div slot="main">
      <ng-container *ngTemplateOutlet="formLayout"> </ng-container>
    </div>

    <fleet-progress-button
      slot="action"
      (onClick)="submit()"
      [state]="{
        loading: loading,
        buttonLabel: blockAttribute ? 'Update' : 'Create'
      }"
      [disabled]="!form.valid || loading"
    >
    </fleet-progress-button>
  </fleet-dialog-layout>
</ng-template>

<ng-template #formLayout>
  <form [formGroup]="form" slot="main" class="flex flex-col">
    <mat-form-field *ngIf="!blockOnly">
      <mat-label>Attribute Type </mat-label>
      <mat-select
        required
        placeholder="Attribute type"
        [formControl]="blockedEntityTypeControl"
      >
        <mat-option [value]="'EMAIL'"> Email </mat-option>
        <mat-option [value]="'PHONE'"> Phone number </mat-option>
        <mat-option [value]="'LOCATION'"> Location </mat-option>
        <mat-option
          [value]="'STORED_CARD'"
          *ngIf="blockAttribute || paymentMethod"
          >Stored card</mat-option
        >
      </mat-select>
      <mat-error>Entity type required</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="blockedEntityTypeControl?.value === 'EMAIL'">
      <mat-label>Email </mat-label>
      <input
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        matInput
        placeholder="Email"
        formControlName="email"
      />
      <mat-error>Email is required</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="blockedEntityTypeControl?.value === 'PHONE'">
      <mat-label>Phone number </mat-label>

      <ngx-mat-intl-tel-input
        [required]="true"
        placeholder="Phone number"
        [enablePlaceholder]="true"
        formControlName="phoneNumber"
      ></ngx-mat-intl-tel-input>
      <mat-error *ngIf="form.get('phoneNumber').errors?.validatePhoneNumber"
        >Invalid phone number</mat-error
      >
      <mat-error *ngIf="form.get('phoneNumber').errors?.required">
        Phone number is required
      </mat-error>
    </mat-form-field>

    <fleet-places-autocomplete
      [required]="true"
      *ngIf="blockedEntityTypeControl?.value === 'LOCATION'"
      [modelType]="'location'"
      formControlName="location"
      label="Address"
      [placeHolder]="'Search Address'"
    >
    </fleet-places-autocomplete>

    <fleet-block-action-selector
      *ngIf="!blockOnly"
      [disabled]="blockAttribute && blockAttribute?.blockType === 'STORED_CARD'"
      formControlName="actionType"
      [required]="true"
    >
    </fleet-block-action-selector>

    <fleet-payment-method-card
      [displayConfig]="$any({ card: true })"
      class="w-full mb-3"
      *ngIf="paymentMethod"
      [paymentMethod]="paymentMethod"
      [iconStyles]="'icon-size-14'"
    >
    </fleet-payment-method-card>

    <fleet-block-reason-selector formControlName="reason" [required]="true">
    </fleet-block-reason-selector>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea
        matInput
        placeholder="Description"
        formControlName="description"
      ></textarea>
      <mat-error>Description is required</mat-error>
    </mat-form-field>
  </form>
</ng-template>
