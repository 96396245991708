import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { JobApiService } from '@fleet/api';
import { ApiResponse, JobModel, JobStatus } from '@fleet/model';
import { OrganisationService } from '@fleet/organisation-shared';
import { OnScreenNotification, OnscreenNotificationService } from '@fleet/ui';
import { failureNotification } from '@fleet/utilities';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { JobDetailService } from '../job-detail/job-detail.service';
import { NetworkGroupService } from '@fleet/network-group';
@Injectable({
  providedIn: 'root',
})
export class JobGuard {
  fleetProduct: string;
  constructor(
    private jobApiService: JobApiService,
    private router: Router,
    private jobService: JobDetailService,
    private onScreenNotifcationService: OnscreenNotificationService,
    @Inject('env') env: any,
    private organisationService: OrganisationService,
    private networkGroupService: NetworkGroupService
  ) {
    this.fleetProduct = env.fleetProduct;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const jobId = route.paramMap.get('jobId');
    return this.jobApiService.getJob(jobId).pipe(
      take(1),
      mergeMap((resp: ApiResponse<JobModel> | any) => {
        const job = resp.data;
        if (job) {
          if (
            job.jobStatus === JobStatus.OPEN ||
            job.jobStatus === JobStatus.ACKNOWLEDGEMENT_REQUIRED
          ) {
            // redirect to job form
            let redirectUrl = '/job/edit/' + job.jobId;
            if (
              this.fleetProduct === 'HUB' &&
              this.organisationService.organisationId
            ) {
              redirectUrl =
                '/organisation/' +
                this.organisationService.organisationId +
                redirectUrl;
            }
            return of(this.router.parseUrl(redirectUrl));
          } else {
            // Get the network group ID from either attribution or fulfilment
            const networkGroupId =
              job.network.attributionNetworkGroupId ||
              job.network.fulfilmentNetworkGroupId;
            const networkId =
              job.network.attributionNetworkId ||
              job.network.fulfilmentNetworkId;

            // Use the new NetworkUserService to initialize the group if needed
            if (this.fleetProduct === 'HUB' && networkGroupId) {
              // Initialize the network group using the new service
              this.networkGroupService.setAttributionNetworkGroup(
                networkId,
                networkGroupId,
                'Job'
              );
            }

            this.jobService.setJob(job);
            return of(true);
          }
        } else {
          return of(false);
        }
      }),
      catchError((error: any) => {
        this.onScreenNotifcationService.setNotification({
          ...failureNotification,
          title: 'Failed to load job!',
          icon: { color: 'red', name: 'warning' },
        } as OnScreenNotification);
        return of(false);
      })
    );
  }
}
