/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Marker } from '@googlemaps/adv-markers-utils';
import { GoogleMap, MapInfoWindow, MapPolyline } from '@angular/google-maps';
import { GoogleMapsApiService } from '@fleet/api';
import { JobStatusUpdateAndProgress, PositionDetailModel } from '@fleet/model';
import { Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { MapService, MapState, MarkerDraggedEvent } from '../../map.service';

import { MapPolylineService } from '../../map-polyline.service';
import { PositionDetailMarker } from './../../map.model';
import { DateTime } from 'luxon';
import { MapAnimationService } from '../../map-animation.service';

@Component({
  selector: 'fleet-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Output() clearActiveJobMarker = new EventEmitter();
  apiLoaded$: Observable<boolean>;
  apiClient: HttpClient;
  apiKey: any;
  vehicleMarkers$: Observable<any[]>;
  heatMapData$: Observable<any[]>;
  routePolyLines$: Observable<any[]>;
  vehicleRankingPolygons$: Observable<any[]>;
  vehicleMarkers: PositionDetailMarker[];
  vehicleMarker: any;
  markers$: Observable<any[]>;
  radiusCircle$: Observable<google.maps.CircleOptions>;
  activeJobMarkers: any[] = [];
  vMarkers: any[] = [];
  movedMarker: MarkerDraggedEvent;
  testMarker: any;
  jobAggregationsPolygons$: Observable<any>;
  vehicleAggregationsPolygons$: Observable<any>;

  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChild('positionDetailWindow', { read: MapInfoWindow })
  info: MapInfoWindow;
  @ViewChild('activeJobInfoWindow', { read: MapInfoWindow })
  activeJobInfo: MapInfoWindow;
  @ViewChild('routeTimestampInfoWindow', { read: MapInfoWindow })
  routeTimeStampInfo: MapInfoWindow;
  @ViewChild('polygonMarkerInfoWindow', { read: MapInfoWindow })
  polygonMarkerInfo: MapInfoWindow;

  @ViewChild('mapPolyline', { read: MapPolyline, static: false })
  set mapPolyline(value: MapPolyline) {
    this.polylineService.mapPolyline = value;
  }
  @Output() boundsUpdated = new EventEmitter();
  selectedPositionDetail: PositionDetailModel;
  selectedActiveJob: JobStatusUpdateAndProgress;

  @Output() vehicleClicked = new EventEmitter();
  @Input() showPositionInfo = false;
  @Input() height = '100vh';
  @Output() activeJobMarkerClicked = new EventEmitter();
  @Output() activeJobSelected = new EventEmitter();
  showPolygons = false;
  polygonMarkers$: Observable<any>;
  polygonMarkers: any = [];
  polyline: any;
  selectedPolylineMarker: any;
  areaPolygons$: Observable<google.maps.PolylineOptions[] | any>;
  selectedPolylinePath: any;

  viewMode: string;
  routeTimelineMarkers: any;

  get polygon$() {
    return this.polylineService.polygon$;
  }

  get polyline$() {
    return this.polylineService.polyline$;
  }

  get routeTimelineMarkers$() {
    return this.mapService.routeTimelineMarkers$;
  }

  get selectedPolylineMarker$() {
    return this.polylineService.selectedPolylinePathMarker$;
  }

  get animatedPolylines$() {
    return this.mapAnimationService.animatedPolylines$;
  }

  get mapReady$() {
    return this.mapService.mapReady$;
  }

  @ViewChild('googleMap', { read: GoogleMap, static: false }) set googleMap(
    value: GoogleMap
  ) {
    this.mapService.googleMap = value;
    this.polylineService.googleMap = value;
    this.mapAnimationService.googleMap = value;
    if (value) {
      value.boundsChanged
        .pipe(
          debounceTime(700),
          map(() => value.getBounds()),
          switchMap((bounds: any) => of(bounds))
        )
        .subscribe((bounds) => {
          this.boundsUpdated.emit(bounds);
        });
    }
  }

  map$: Observable<MapState>;
  options: google.maps.MapOptions = {
    // disableDefaultUI: true,
    zoomControl: true,
    streetViewControl: false,
    scaleControl: false,
  };

  constructor(
    private googleMapApiService: GoogleMapsApiService,
    private mapService: MapService,
    private mapAnimationService: MapAnimationService,
    private changeDetectorRef: ChangeDetectorRef,
    private polylineService: MapPolylineService
  ) {}

  ngOnInit(): void {
    this.areaPolygons$ = this.mapService.areaPolygons$;
    this.apiLoaded$ = this.googleMapApiService.isApiLoaded$;
    this.map$ = this.mapService.map$;
    this.markers$ = this.mapService.markers$;
    this.vehicleMarkers$ = this.mapService.vehicleMarkers$;
    this.heatMapData$ = this.mapService.heatMapData$;
    this.radiusCircle$ = this.mapService.radiusCircle;
    this.polygonMarkers$ = this.polylineService.polygonMarkers$;
    this.vehicleAggregationsPolygons$ = this.mapService.vehicleAggregations$;
    this.jobAggregationsPolygons$ = this.mapService.jobAggregations$;

    this.mapService.routeTimelineMarkers$.subscribe({
      next: (markers: any) => {
        this.routeTimelineMarkers = markers;
        this.changeDetectorRef.markForCheck();
      },
    });
    this.vehicleRankingPolygons$ = this.mapService.vehicleRankingPolygons$;
    this.mapService.vehicleMarker$.subscribe({
      next: (marker: any) => {
        if (marker) {
          if (
            this.vehicleMarker &&
            marker.vehicleId === this.vehicleMarker.vehicleId
          ) {
            this.vehicleMarker.setPosition(marker.position);
            this.vehicleMarker.setIcon(marker.icon);
          } else {
            this.vehicleMarker = marker;
          }
        } else {
          this.vehicleMarker = null;
        }
        this.changeDetectorRef.markForCheck();
      },
    });
    this.routePolyLines$ = this.mapService.routePolyLines$;

    this.mapService.activeJobMarkers$.subscribe({
      next: (markers: any) => {
        if (markers) {
          //this.activeJobMarkers = markers;
          if (markers.length > 0) {
            const markersToAdd: any[] = [];

            markers.forEach((marker: any) => {
              const foundMarker = this.activeJobMarkers.find(
                (s) =>
                  s.data_?.activeJob.latestStatusUpdate.jobId ===
                  marker.data_?.activeJob.latestStatusUpdate.jobId
              ) as Marker;
              if (foundMarker) {
                foundMarker.content = marker.content;
                // foundMarker.setOpacity(marker.opacity);
              } else {
                markersToAdd.push(marker);
              }
            });
            this.activeJobMarkers.push(...markersToAdd);
            for (let index = 0; index < this.activeJobMarkers.length; index++) {
              const activeMarker = markers.find(
                (m: any) =>
                  m.data_?.activeJob.latestStatusUpdate.jobId ===
                  this.activeJobMarkers[index].data_?.activeJob
                    .latestStatusUpdate.jobId
              );
              if (!activeMarker) {
                this.activeJobMarkers.splice(index, 1);
                index--;
              }
            }
            this.changeDetectorRef.markForCheck();
          } else {
            this.activeJobMarkers = [];
          }
        } else {
          this.activeJobMarkers = [];
        }
      },
    });
    this.mapService.vehicleMarkers$.subscribe({
      next: (markers: any) => {
        if (markers) {
          if (markers.length > 0) {
            const markersToAdd: any[] = [];
            const markersToDelete = [];
            markers.forEach((marker: any) => {
              const foundMarker = this.vMarkers.find(
                (s) => s.vehicleId == marker.vehicleId
              );
              if (foundMarker) {
                foundMarker.setPosition(marker.position);
                foundMarker.setIcon(marker.icon);
              } else {
                markersToAdd.push(marker);
              }
            });
            //find out who doesnt existin
            this.vMarkers.push(...markersToAdd);
            for (let index = 0; index < this.vMarkers.length; index++) {
              const vehicle = markers.find(
                (m: any) => m.vehicleId === this.vMarkers[index].vehicleId
              );
              if (!vehicle) {
                this.vMarkers.splice(index, 1);
                index--;
              }
            }
          } else {
            this.vMarkers = [];
          }
        } else {
          this.vMarkers = [];
        }
      },
    });

    this.polylineService.polyline$.subscribe({
      next: (polyline: any) => {
        setTimeout(() => {
          this.polyline = null;
          this.polyline = polyline;
          this.changeDetectorRef.detectChanges();
        }, 100);
        if (polyline) {
        }
      },
    });

    this.polylineService.selectedPolylinePath.subscribe({
      next: (path: any) => {
        this.selectedPolylinePath = path;

        if (path) {
          // if (this.selectedPolylineMarker) {
          //   this.selectedPolylineMarker.setPosition({
          //     lat: +path[0],
          //     lng: +path[1],
          //   });
          // } else {
          this.selectedPolylineMarker = new google.maps.Marker({
            position: {
              lat: +path[0],
              lng: +path[1],
            },
            // icon: circleMarkerUrl('#fcba03'),
            // anchor: new google.maps.Point(5, 5),
          } as google.maps.MarkerOptions);
        } else {
          this.selectedPolylineMarker = null;
        }
        // }
      },
    });

    this.polylineService.polygonMarkers$.subscribe({
      next: (markers: any) => {
        if (markers) {
          if (markers.length > 0 && this.polygonMarkers.length > 0) {
            const markersToAdd: any[] = [];
            this.polygonMarkers = markers;
          } else {
            this.polygonMarkers = markers;
          }
        } else {
          this.polygonMarkers = [];
        }
      },
    });

    this.mapService.activeJob$.subscribe({
      next: (activeJob: JobStatusUpdateAndProgress) => {
        if (!activeJob && this.activeJobInfo) {
          this.activeJobInfo.close();
        }
        this.selectedActiveJob = activeJob;
      },
    });

    this.mapService.viewMode$.subscribe({
      next: (viewMode: string) => {
        this.viewMode = viewMode;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.mapService.googleMap = this.googleMap;

    this.areaPolygons$.subscribe((updates: any) => {
      this.showPolygons = false;

      setTimeout(() => {
        this.showPolygons = true;
      }, 100);
    });
  }

  click(event: any) {
    console.log(this.activeJobInfo);
    this.mapService.mapClick.next(event.latLng);
    this.deselectActiveJob();
  }

  mouseMove(event: any) {
    //console.log('Map Mouse Move: ' + event);
  }

  markerDragEnd(index: any, event: any) {
    this.movedMarker = <MarkerDraggedEvent>{
      index: index,
      position: event.latLng,
    };
    this.mapService.setConfirmLocationNeeded(this.movedMarker);
  }

  confirmLocation() {
    this.mapService.confirmLocation();
  }

  confirmMovedMarker() {
    this.mapService.confirmLocation(this.movedMarker);
  }

  vehiclePositionClicked(event: any, markerRef: any) {
    // event.positionDetail.vehicleId = 'a17179149';
    // event.positionDetail.driverId = 'a2e2f2292';
    // event.vehicleId = 'a17179149';
    // event.driverId = 'a2e2f2292';
    this.selectedPositionDetail = event.positionDetail;

    if (this.showPositionInfo) {
      this.info.open(markerRef);
    }

    this.vehicleClicked.emit({
      driverId: event.driverId,
      vehicleId: event.vehicleId,
    });
  }

  activeJobClicked(event: any, markerRef: any) {
    this.activeJobInfo.options = {
      pixelOffset: new google.maps.Size(-3, -8),
      disableAutoPan: true,
      position: event.position,
    } as any;
    this.activeJobInfo.open();

    this.activeJobMarkerClicked.emit(event.data_.activeJob);
  }

  deselectActiveJob() {
    this.selectedActiveJob = null;
    this.mapService.clearActiveJobMarkers();
    this.activeJobMarkerClicked.emit(null);
  }

  areaPolygonClicked(polygon: google.maps.PolylineOptions) {
    this.mapService.areaSelected(polygon);
  }

  polylineClicked(event: any) {
    this.polylineService.setSelectedPolylinePathByLatLng(event.latLng);
  }

  polylineDragend(event: any) {
    this.polylineService.polylineDragEnd(event.latLng);
  }

  polylineMouseUp(event: google.maps.PolyMouseEvent) {
    this.polylineService.polylineDragEnd(event);
  }

  polylineDoubleClick(event: google.maps.PolyMouseEvent) {
    console.log(JSON.stringify(event));
  }
  polylineMousedown(event: google.maps.PolyMouseEvent) {}

  polygonMarkerClicked(event: any, markerRef: any) {
    // this.polylineService.selectedPolylineMarker.next(event);
    // this.polylineService.selectedPolylinePath.next(event.path);

    this.polylineService.setSelectedPolyLinePathFromMarkerPath(event.path);
    setTimeout(() => {
      this.polygonMarkerInfo.open(markerRef);
    }, 1);
  }

  deletePolyMarker(selectedPolygonPath: string) {
    this.polygonMarkerInfo.close();
    this.polylineService.removePath(selectedPolygonPath);
  }

  contextMenu(event: any) {
    console.log(event);
    this.polylineService.addPathFromLatLng(event.latLng);
  }

  routeTimelineMarkerClicked(event: any, markerRef: any) {
    const timestamp = markerRef.marker.timestamp
      ? DateTime.fromMillis(markerRef.marker.timestamp * 1000).toFormat(
          'HH:mm:ss'
        )
      : null;

    this.routeTimeStampInfo.options = {
      pixelOffset: new google.maps.Size(-3, -8),
      disableAutoPan: true,
      position: event.getPosition(),
      content: `<div class='flex flex-col items-start gap-2 text-sm'>
      <div class="flex items-center"><span class='material-icons text-base leading-none text-gray'>access_time</span><span class="ml-1">${timestamp}</span></div>
      <div class="flex items-center"><span class='material-icons text-base leading-none text-gray'>place</span><span class="ml-1">${markerRef.marker.positionDisplay}</span></div>
      </div>`,
    };
    this.routeTimeStampInfo.open();
  }
}
