import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { JobDetailService } from '../job-detail/job-detail.service';
import { NetworkGroupService } from '@fleet/network-group';
@Injectable({
  providedIn: 'root',
})
export class JobDeactivateGuard {
  constructor(
    private jobDetailService: JobDetailService,
    private networkGroupService: NetworkGroupService
  ) {}
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.jobDetailService.setJob(null);
    this.networkGroupService.clearGroupIfAggregator();
    return true;
  }
}
