import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  BlocklistApiService,
  DriverApiService,
  OperatorApiService,
  OrganisationApiService,
  TravellerApiService,
} from '@fleet/api';
import { AuthService } from '@fleet/auth';
import { phoneNumberValidator } from '@fleet/custom-controls';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  BlockAttributeModel,
  IssueModel,
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';
import { NetworkGroupService } from '@fleet/network-group';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fleet-blocklist-attribute-create-edit',
  templateUrl: './blocklist-attribute-create-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class BlocklistAttributeCreateEditComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() mode: 'sidebar' | 'dialog' = 'sidebar';
  loading: boolean;
  issues: IssueModel[];
  form: UntypedFormGroup;

  blockedEntityTypeControl: UntypedFormControl = new UntypedFormControl();

  @Input() blockOnly = false;

  _paymentMethod: PaymentMethodModel;
  @Input() set paymentMethod(value: any) {
    // this._paymentMethod = value;
    if (value && value.paymentMethodId) {
      //need to get full version of payment method
      if (!this.form) {
        this.buildForm();
      }

      let apiCall;

      if (value.travellerId) {
        apiCall = this.travellerApiService.getPaymentMethod(
          value.travellerId,
          value.paymentMethodId
        );
      } else if (value.organisationId) {
        apiCall = this.organisationApiService.getPaymentMethod(
          value.paymentMethodId,
          value.organisationId
        );
      } else if (value.driverId) {
        apiCall = this.driverApiService.getPaymentMethod(
          value.driverId,
          value.paymentMethodId
        );
      } else if (value.operatorId) {
        apiCall = this.operatorApiService.getPaymentMethod(
          value.paymentMethodId,
          value.operatorId
        );
      }

      this.loading = true;
      this.issues = [];
      this.changeDetectorRef.markForCheck();

      apiCall.subscribe({
        next: (resp: ApiResponse<PaymentMethodModel>) => {
          this._paymentMethod = resp.data;
          this.form.get('storedCard').patchValue(resp.data.card.cardId);
          this.blockedEntityTypeControl.patchValue('STORED_CARD');
          this.blockedEntityTypeControl.disable();

          this.form.get('actionType').setValue('BLOCK');
          this.form.get('actionType').disable();
          this.form.get('actionType').updateValueAndValidity();

          this.form.get('storedCard').disable();
          this.form.get('storedCard').updateValueAndValidity();

          this.loading = false;

          this.changeDetectorRef.markForCheck();
        },
        error: (issues: IssueModel[]) => {
          this.issues = issues;
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        },
      });
    } else if (value && !value.paymentMethodId) {
      //this is actually just the update opening of stored card blocked attribute - no payment method id involved, cannot do get - payment method powers the card.
      this._paymentMethod = value;

      this.form.get('actionType').setValue('BLOCK');
      this.form.get('actionType').disable();
      this.form.updateValueAndValidity();

      this.changeDetectorRef.markForCheck();
    }
    this.changeDetectorRef.markForCheck();
  }

  get paymentMethod() {
    return this._paymentMethod;
  }

  @Output() updateSuccessful = new EventEmitter();
  @Output() createSuccessful = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  _blockAttributeId: string;
  @Input() set blockAttributeId(value: string) {
    this._blockAttributeId = value;
    if (value) {
      if (!this.form) {
        this.buildForm();
      }
      this.loading = true;
      this.issues = [];
      this.changeDetectorRef.markForCheck();

      //This right now is a search result - it is CURRENTLY the same object but this will change to a search result later
      // - REGARDLESS - we need to do a get to get the fulkl template
      this.blocklistApiService.getBlocklistAttribute(value).subscribe({
        next: (resp: ApiResponse<BlockAttributeModel> | any) => {
          const attribute = resp.data as BlockAttributeModel;
          this.loading = false;
          this.blockAttribute = attribute;

          this.form.patchValue(attribute);

          if (attribute.phoneNumber) {
            this.blockedEntityTypeControl.patchValue('PHONE');
            this.form.get('phoneNumber').disable();
          }
          if (attribute.email) {
            this.blockedEntityTypeControl.patchValue('EMAIL');
            this.form.get('email').disable();
          }

          if (attribute.location) {
            this.blockedEntityTypeControl.patchValue('LOCATION');
            this.form.get('location').disable();
          }

          if (attribute.storedCard) {
            this.blockedEntityTypeControl.patchValue('STORED_CARD');
            this.form
              .get('storedCard')
              .setValue(resp.data.storedCard.maskedCardNumber);

            this.form.get('storedCard').disable();
          }
          this.blockedEntityTypeControl.disable();

          this.form.get('actionType').patchValue(attribute.actionType);

          this.changeDetectorRef.markForCheck();
        },
        error: (issues: IssueModel[]) => {
          this.issues = issues;
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        },
      });
    }
  }

  blockAttribute: BlockAttributeModel = null;
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private blocklistApiService: BlocklistApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private networkGroupService: NetworkGroupService,
    private organisationApiService: OrganisationApiService,
    private travellerApiService: TravellerApiService,
    private operatorApiService: OperatorApiService,
    private driverApiService: DriverApiService
  ) {}

  ngOnInit(): void {
    if (!this.form) {
      this.buildForm();
    }

    this.blockedEntityTypeControl.valueChanges.subscribe((value: string) => {
      switch (value) {
        case 'LOCATION':
          this.form.get('location').setValidators([Validators.required]);
          this.form.get('actionType').setValidators([Validators.required]);

          this.form.get('phoneNumber').clearValidators();
          this.form.get('email').clearValidators();
          break;
        case 'EMAIL':
          this.form
            .get('email')
            .setValidators([Validators.required, Validators.email]);
          this.form.get('actionType').reset();
          this.form.get('actionType').setValidators([Validators.required]);
          this.form.get('location').clearValidators();
          this.form.get('phoneNumber').clearValidators();
          this.form.get('storedCard').clearValidators();

          break;
        case 'PHONE':
          this.form
            .get('phoneNumber')
            .setValidators([Validators.required, phoneNumberValidator()]);
          this.form.get('actionType').reset();
          this.form.get('actionType').setValidators([Validators.required]);

          this.form.get('location').clearValidators();
          this.form.get('email').clearValidators();
          this.form.get('storedCard').clearValidators();

          break;
        case 'STORED_CARD':
          this.form.get('phoneNumber').clearValidators();
          this.form.get('location').clearValidators();
          this.form.get('email').clearValidators();
          this.form.get('actionType').clearValidators();
          this.form.get('actionType').setValue('BLOCK');
          this.form.get('actionType').disable();

          this.form.get('storedCard').setValidators([Validators.required]);

          break;
        default:
          this.form.get('actionType').setValidators([Validators.required]);
          this.form.get('phoneNumber').clearValidators();
          this.form.get('location').clearValidators();
          this.form.get('email').clearValidators();

          this.form.get('storedCard').clearValidators();
          break;
      }
      this.form.get('email').updateValueAndValidity();
      this.form.get('location').updateValueAndValidity();
      this.form.get('phoneNumber').updateValueAndValidity();
      this.form.get('actionType').updateValueAndValidity();
      this.form.get('storedCard').updateValueAndValidity();

      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }

  buildForm() {
    this.form = this.fb.group({
      email: [null, [Validators.email]],
      phoneNumber: [],
      location: [],
      description: [],
      storedCard: [],
      reason: [null, Validators.required],
      actionType: [null, Validators.required],
    });
  }

  ngAfterViewInit(): void {
    if (!this.blockOnly) {
      this.blockedEntityTypeControl.setValue('EMAIL');
    }
  }

  submit() {
    if (this.blockAttribute?.blockAttributeId) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    let payload = Object.assign({}, this.form.value);

    if (this.authService.networkId) {
      payload['networkId'] = this.authService.networkId;
    }
    if (this.networkGroupService.networkGroupId) {
      payload['networkGroupId'] = this.networkGroupService.networkGroupId;
    }

    if (this.paymentMethod) {
      payload['storedCard'] = { cardId: this.paymentMethod.card.cardId };
      payload['actionType'] = 'BLOCK';
    } else {
      delete payload['storedCard'];
    }

    this.issues = [];
    this.loading = true;
    this.changeDetectorRef.markForCheck();

    this.blocklistApiService.createBlocklistAttribute(payload).subscribe({
      next: (resp: ApiResponse<BlockAttributeModel>) => {
        this.loading = false;
        this.createSuccessful.emit(resp.data);
        this.changeDetectorRef.markForCheck();
      },
      error: (error: any) => {
        this.loading = false;
        this.issues = error;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  update() {
    let payload = Object.assign({}, this.blockAttribute, this.form.value);

    if (this.authService.networkId) {
      payload['networkId'] = this.authService.networkId;
    }
    if (this.networkGroupService.networkGroupId) {
      payload['networkGroupId'] = this.networkGroupService.networkGroupId;
    }

    // if (payload['storedCard']) {
    //   payload['storedCard'] = { cardId: this.paymentMethod.card.cardId };
    // } else {
    delete payload['storedCard'];
    // }

    this.issues = [];
    this.loading = true;
    this.changeDetectorRef.markForCheck();

    this.blocklistApiService
      .updateBlocklistAttribute(this.blockAttribute.blockAttributeId, payload)
      .subscribe({
        next: (resp: ApiResponse<BlockAttributeModel>) => {
          this.loading = false;
          this.updateSuccessful.emit(resp.data);
          this.changeDetectorRef.markForCheck();
        },
        error: (error: any) => {
          this.loading = false;
          this.issues = error;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  isEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
