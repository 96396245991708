import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DriverApiService,
  NetworkApiService,
  NetworkGroupApiService,
} from '@fleet/api';
import { AuthService } from '@fleet/auth';

import { LocaleService } from '@fleet/locale';

import {
  ApiResponse,
  AreaModel,
  NetworkGroupModel,
  NetworkModel,
  ReviewModel,
} from '@fleet/model';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { TranslocoService } from '@jsverse/transloco';
import { Settings } from 'luxon';

import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  distinctUntilChanged,
} from 'rxjs/operators';
import { FleetNavigationService } from '@fleet/navigation';
@Injectable({
  providedIn: 'root',
})
export class NetworkGroupService {
  selectedGroup: BehaviorSubject<NetworkGroupModel> = new BehaviorSubject(null);
  outstandingDriverReviews: BehaviorSubject<ReviewModel[]> =
    new BehaviorSubject([]);
  serviceArea: BehaviorSubject<AreaModel> = new BehaviorSubject(null);
  fleetProduct: string;
  // New properties for network and available groups
  private network = new BehaviorSubject<NetworkModel>(null);
  private availableGroups = new BehaviorSubject<NetworkGroupModel[]>([]);

  // Track whether the network group has been initialized
  private networkGroupInitialized = new BehaviorSubject<boolean>(false);
  private aggregator = false;
  private attributionNetworkGroup = new BehaviorSubject<NetworkGroupModel>(
    null
  );
  private attributionEntity = new BehaviorSubject<string>(null);
  constructor(
    private driverApiService: DriverApiService,
    private networkGroupApiService: NetworkGroupApiService,
    private networkApiService: NetworkApiService,
    private authService: AuthService,
    private productConfigurationService: ProductConfigurationService,
    private localeService: LocaleService,
    private translocoService: TranslocoService,
    private fleetNavigationService: FleetNavigationService,
    @Inject('env') env: any
  ) {
    this.fleetProduct = env.fleetProduct;
    // Set up a listener for when both network is set and network group is initialized
    combineLatest([
      this.network.asObservable().pipe(filter((network) => !!network)),
      this.networkGroupInitialized
        .asObservable()
        .pipe(filter((initialized) => initialized)),
    ]).subscribe(([network, _]) => {
      console.log('Network set and group initialized, searching service areas');
      this.searchServiceAreas();
    });

    // lets initate the network and network group from the user and product configuration
    if (env.fleetProduct !== 'TRAVELLER') {
      combineLatest([
        this.productConfigurationService.networkConfig$,
        this.authService.user$.pipe(
          filter((user) => !!user),
          take(1)
        ),
      ]).subscribe(([networkConfig, user]) => {
        if (networkConfig.networkId) {
          this.getNetwork(networkConfig.networkId);
        }
        if (networkConfig.networkGroupId) {
          this.getNetworkGroup(
            networkConfig.networkGroupId,
            networkConfig.networkId
          );
        } else {
          if (this.fleetProduct === 'HUB') {
            this.aggregator = true;
          }
          this.setSelectedGroup(null);
        }
      });
    } else {
      //lets get the network from traveller based off product configuration
      this.productConfigurationService.networkConfig$.subscribe(
        (networkConfig) => {
          if (networkConfig && networkConfig.networkId) {
            this.getNetwork(networkConfig.networkId);
          }
          if (networkConfig && networkConfig.networkGroupId) {
            this.getNetworkGroup(
              networkConfig.networkGroupId,
              networkConfig.networkId
            );
          } else {
            this.setSelectedGroup(null);
          }
        }
      );
    }
  }

  getNetwork(networkId: string) {
    this.networkApiService.getNetwork(networkId).subscribe({
      next: (resp: ApiResponse<NetworkModel>) => {
        this.network.next(resp.data);
      },
    });
  }

  get networkId() {
    return this.network.value?.networkId;
  }

  get attributionNetworkGroup$() {
    return this.attributionNetworkGroup.asObservable();
  }

  get attributionEntityAndGroup$() {
    return combineLatest([
      this.attributionEntity.asObservable(),
      this.networkGroup$,
    ]);
  }

  setSelectedGroup(group: NetworkGroupModel) {
    if (group) {
      if (this.fleetProduct === 'HUB') {
        this.fleetNavigationService.setCurrentGroupByNetworkGroup(group);
      }
      Settings.defaultZone = group.timezone;
      Settings.defaultLocale = group.locale;

      // Setting locale service
      if (group.locale) {
        this.localeService.setLocale(group.locale);
        this.translocoService.setActiveLang(
          group.locale.replace(/-/g, '_').toLowerCase()
        );
      }

      if (group.type === 'TAXI_NETWORK') {
        // Service areas will be searched when both network is set and group is initialized
        // This is now handled by the combineLatest in the constructor
      } else {
        // Clear service area if not a taxi network
        this.serviceArea.next(null);
      }
    } else {
      if (this.fleetProduct === 'HUB') {
        this.fleetNavigationService.setCurrentGroup(null);
      }
      if (this.network.value) {
        this.translocoService.setActiveLang(
          this.network.value.locale.replace(/-/g, '_').toLowerCase()
        );
        this.localeService.setLocale(this.network.value.locale);
      }
      // Handle null group - reset to defaults
      this.serviceArea.next(null);

      // Reset locale to default if needed
      // this.localeService.setLocale('en-US');
      // this.translocoService.setActiveLang('en_us');
    }

    // Always update the group and initialization state
    this.selectedGroup.next(group);
    this.networkGroupInitialized.next(true);
  }

  // Search for service areas based on current network group
  private searchServiceAreas() {
    this.serviceArea.next(null);
    const group = this.selectedGroup.value;
    const networkId = this.network.value?.networkId;

    if (!networkId) {
      return;
    }

    this.networkApiService
      .searchServiceAreas(networkId, {
        ...(group?.networkGroupId && { networkGroupId: group.networkGroupId }),
        includePolygon: true,
      })
      .subscribe({
        next: (resp: HttpResponse<ApiResponse<AreaModel[]>> | any) => {
          this.serviceArea.next(
            resp.body.data.length > 0 ? resp.body.data[0] : null
          );
          console.log('Service area updated:', this.serviceArea.value);
        },
        error: (err: any) => {
          console.error('Error fetching service areas:', err);
          this.serviceArea.next(null);
        },
      });
  }

  getNetworkGroup(networkGroupId: string, networkId: string) {
    this.networkGroupApiService
      .getNetworkGroup(networkGroupId, networkId)
      .subscribe({
        next: (resp: ApiResponse<NetworkGroupModel>) => {
          this.setSelectedGroup(resp.data);
        },
      });
  }

  get networkGroup$() {
    return this.selectedGroup.asObservable();
  }

  // Observable that only emits when network group is initialized
  get initializedNetworkAndNetworkGroup$(): Observable<{
    network: NetworkModel;
    networkGroup: NetworkGroupModel;
  }> {
    return combineLatest([
      this.selectedGroup.asObservable(),
      this.networkGroupInitialized.asObservable(),
      this.network.asObservable(),
    ]).pipe(
      filter(([_, initialized, network]) => {
        return initialized == true && network != null;
      }),
      map(([networkGroup, _, network]) => ({ networkGroup, network })),
      distinctUntilChanged((prev, curr) => {
        return (
          prev.networkGroup?.networkGroupId ===
            curr.networkGroup?.networkGroupId &&
          prev.network?.networkId === curr.network?.networkId
        );
      })
    );
  }

  // Observable that emits when network has a value, initialized is true,
  // and includes the current networkGroup (which can be null)
  // Now also includes serviceArea and waits for it to be available
  get initializedNetworkState$(): Observable<{
    network: NetworkModel;
    networkGroup: NetworkGroupModel | null;
    serviceArea: AreaModel | null;
  }> {
    return combineLatest([
      this.network.asObservable().pipe(filter((network) => !!network)),
      this.networkGroupInitialized
        .asObservable()
        .pipe(filter((initialized) => initialized)),
      this.selectedGroup.asObservable(),
      this.serviceArea
        .asObservable()
        .pipe(filter((serviceArea) => !!serviceArea)),
    ]).pipe(
      // Wait for the service area to be loaded (even if it's null)

      map(([network, _, networkGroup, serviceArea]) => ({
        network,
        networkGroup,
        serviceArea,
      }))
    );
  }

  // Check if network group has been initialized
  get isNetworkGroupInitialized(): boolean {
    return this.networkGroupInitialized.value;
  }

  // Observable for initialization state
  get networkGroupInitialized$(): Observable<boolean> {
    return this.networkGroupInitialized.asObservable();
  }

  get networkGroupId() {
    return this.selectedGroup.value
      ? this.selectedGroup.value.networkGroupId
      : null;
  }

  get regionCode() {
    return this.selectedGroup.value
      ? this.selectedGroup.value.regionCode
      : null;
  }

  get serviceArea$() {
    return this.serviceArea.asObservable();
  }

  get networkGroupAndServiceArea$() {
    return combineLatest([this.networkGroup$, this.serviceArea$]).pipe(
      map(([group, area]) => {
        return { networkGroup: group, serviceArea: area };
      })
    );
  }

  get networkGroupAndServiceArea() {
    return {
      networkGroup: this.selectedGroup.value,
      serviceArea: this.serviceArea.value,
    };
  }

  get searchLatLngAndCountryCode$() {
    return this.networkGroupAndServiceArea$.pipe(
      map((groupAndArea: any) => {
        if (groupAndArea.networkGroup && groupAndArea.serviceArea) {
          return {
            latitude: groupAndArea.serviceArea.point.latitude,
            longitude: groupAndArea.serviceArea.point.longitude,

            countryCode: groupAndArea.networkGroup.countryCode,
          };
        } else {
          return null;
        }
      })
    );
  }

  fetchGroupState(networkGroupId: string, networkId: string) {
    const calls = [
      this.driverApiService.searchReviews({
        networkGroupId: networkGroupId,
        networkId: networkId,
        status: 'PENDING,IN_PROGRESS',
      }),
    ];
    forkJoin(calls).subscribe({
      next: ([reviewResp]) => {
        this.outstandingDriverReviews.next(reviewResp.data);
      },
    });
  }

  get networkGroupTimezone() {
    return this.selectedGroup.value && this.selectedGroup.value.timezone
      ? this.selectedGroup.value.timezone
      : null;
  }

  // Getters for new observables
  get network$(): Observable<NetworkModel> {
    return this.network.asObservable();
  }

  get availableGroups$(): Observable<NetworkGroupModel[]> {
    return this.availableGroups.asObservable();
  }

  get hasAvailableGroups$(): Observable<boolean> {
    return this.availableGroups.pipe(
      map((groups) => groups && groups.length > 0)
    );
  }

  setAttributionNetworkGroup(
    networkId: string,
    networkGroupId: string,
    entity: string
  ) {
    if (this.aggregator) {
      this.attributionEntity.next(entity);
      this.getNetworkGroup(networkGroupId, networkId);
    }
  }

  clearGroupIfAggregator() {
    if (this.fleetProduct === 'HUB' && this.aggregator) {
      this.setSelectedGroup(null);
      this.attributionEntity.next(null);
      this.attributionNetworkGroup.next(null);
      this.fleetNavigationService.setCurrentGroupByNetworkGroup(null);
    }
  }
}
