import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';

import { SettingsApiService } from '@fleet/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OnscreenNotificationService } from '@fleet/ui';
import { NetworkGroupService } from '@fleet/network-group';

import {
  ApiResponse,
  IssueModel,
  SettingDefinitionModel,
  SettingModel,
  SettingSearchResultModel,
} from '@fleet/model';
import { SettingService } from '../setting.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { failureNotification, successNotification } from '@fleet/utilities';

@Component({
  template: '',
})
export class SettingBaseComponent implements OnDestroy {
  protected _unsubscribeAll: Subject<any> = new Subject<any>();
  setting: SettingModel;
  issues: IssueModel[] = [];

  saving = false;
  loading = false;
  settingDefinition: BehaviorSubject<SettingDefinitionModel> =
    new BehaviorSubject<SettingDefinitionModel>(null);
  path: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  sharedData: any;
  originalSetting: any;
  networkId: string;
  networkGroupId: string;
  hasChanged = false;
  fields: FormlyFieldConfig[] = [];
  model: any = {};
  @Output() settingCreated = new EventEmitter<SettingModel>();
  @Output() settingUpdated = new EventEmitter<SettingModel>();

  constructor(
    protected settingsApiService: SettingsApiService,
    protected settingService: SettingService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected onscreenNotificationService: OnscreenNotificationService,
    protected networkGroupService: NetworkGroupService
  ) {
    combineLatest([
      this.networkGroupService.initializedNetworkAndNetworkGroup$,
      this.path.asObservable(),
    ])
      .pipe(
        takeUntilDestroyed(),
        filter(([networkState, path]) => !!path),
        switchMap(([networkState, path]) => {
          if (networkState.networkGroup) {
            this.networkGroupId = networkState?.networkGroup?.networkGroupId;
          } else {
            this.networkGroupId = null;
          }
          return this.settingService.settingByPath$(path).pipe(
            filter((settingsResp) => settingsResp !== null),
            switchMap((setting: SettingSearchResultModel) => {
              if (!setting) {
                return of(null);
              }
              this.loading = true;
              this.changeDetectorRef.markForCheck();
              return this.settingsApiService.getSettingDefinition(
                setting.settingDefinitionId,
                networkState.networkGroup?.networkGroupId
              );
            })
          );
        }),
        filter((resp) => resp !== null)
      )
      .subscribe((resp: ApiResponse<SettingDefinitionModel>) => {
        this.loading = false;
        this.sharedData = resp.data.sharedData;
        this.setting = resp.data.setting;
        this.settingDefinition.next(resp.data);
        this.originalSetting = JSON.parse(
          JSON.stringify(resp.data.setting.data)
        );

        this.model = JSON.parse(JSON.stringify(resp.data.setting.data));
        this.changeDetectorRef.markForCheck();
      });

    this.settingService.issues$
      .pipe(takeUntilDestroyed())
      .subscribe((issues: IssueModel[]) => {
        this.issues = issues;
        this.changeDetectorRef.markForCheck();
      });
  }

  getSettingByPath(path: string): Observable<any> {
    return this.settingService.settingByPath$(path).pipe(
      takeUntilDestroyed(),
      switchMap((setting: SettingSearchResultModel) => {
        if (setting) {
          this.loading = true;
          this.changeDetectorRef.markForCheck();
          return this.settingsApiService.getSettingDefinition(
            setting.settingDefinitionId,
            this.networkGroupId
          );
        }
        return of(null);
      }),
      filter((resp) => resp !== null),
      tap((resp: ApiResponse<SettingDefinitionModel>) => {
        this.loading = false;
        this.settingDefinition.next(resp.data);
        this.originalSetting = JSON.parse(
          JSON.stringify(this.settingDefinition.getValue().setting.data)
        );
        this.setting = this.settingDefinition.getValue().setting;
        this.model = JSON.parse(
          JSON.stringify(this.settingDefinition.getValue().setting.data)
        );
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  saveSetting(data: any) {
    this.issues = [];
    this.saving = true;
    if (this.setting.networkId) {
      this.updateSetting({
        ...this.setting,
        networkId: this.networkGroupService.networkId,
        networkGroupId: this.networkGroupId,
        data: data,
      });
    } else {
      this.createSetting({
        data: data,
        settingDefinitionId:
          this.settingDefinition.getValue().settingDefinitionId,
        networkId: this.networkGroupService.networkId,
        networkGroupId: this.networkGroupId,
      } as SettingModel);
    }
  }

  createSetting(setting: SettingModel) {
    this.settingsApiService.createSetting(setting).subscribe({
      next: (resp: ApiResponse<SettingModel>) => {
        this.setting = resp.data;
        this.settingCreated.emit(this.setting);
        this.saving = false;
        this.onscreenNotificationService.setNotification({
          ...successNotification,
          title: 'Success',
          subTitle: 'Setting updated successfully',
          autoDismiss: 3000,
        });
      },
      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.saving = false;
        this.changeDetectorRef.markForCheck();
        this.onscreenNotificationService.setNotification({
          ...failureNotification,
          title: 'Error',
          subTitle: 'Failed to update setting',
          autoDismiss: 3000,
        });
      },
    });
  }

  updateSetting(setting: SettingModel) {
    this.settingsApiService
      .updateConfiguration(setting.settingId, setting)
      .subscribe({
        next: (resp: ApiResponse<SettingModel>) => {
          this.setting = resp.data;
          this.settingUpdated.emit(resp.data);
          this.saving = false;
          this.hasChanged = false;
          this.onscreenNotificationService.setNotification({
            ...successNotification,
            title: 'Success',
            subTitle: 'Setting updated successfully',
            autoDismiss: 3000,
          });

          this.changeDetectorRef.markForCheck();
        },

        error: (issues: IssueModel[]) => {
          this.issues = issues;
          this.saving = false;
          this.changeDetectorRef.markForCheck();
          this.onscreenNotificationService.setNotification({
            ...failureNotification,

            title: 'Error',
            subTitle: 'Failed to update setting',
            autoDismiss: 3000,
          });
        },
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
