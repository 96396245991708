import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { FuseMediaWatcherService } from '@fleet/fuse';
import { BaseSearchState } from '@fleet/model';
import { fromEvent, interval, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  map,
  pairwise,
  startWith,
  takeUntil,
  throttle,
} from 'rxjs/operators';
import { FleetNavigationService } from '@fleet/navigation';
import { ProductConfigurationService } from '@fleet/product-configuration';

@Component({
  selector: 'fleet-entity-search-layout',
  templateUrl: './entity-search-layout.component.html',
  styleUrls: ['./entity-search-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySearchLayoutComponent implements OnInit, OnDestroy {
  @Output() drawerClosed = new EventEmitter();
  @Input() hideDrawer = false;
  @Input() resultEntityType: string;

  _searchData: BaseSearchState<any>;
  @Input() set searchData(value: BaseSearchState<any>) {
    this._searchData = value;

    if (this.drawerMode === 'over' && this.drawerOpened && !value.searching) {
      //Currently open and the drawer is in over mode.
      this.closeDrawer();
    }
  }

  get searchData() {
    return this._searchData;
  }

  @Input() showFilter = true;
  @ViewChild('searchResults', {
    read: ElementRef,
    static: false,
  })
  public searchResultsRef: ElementRef;
  @Input() largeWidth = false;

  @Input() hasPageData = true;

  @Input() functionTypeAndAction: string = null;
  @Output() backdropClosed = new EventEmitter();

  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;

  @Output() nextDrawerPage = new EventEmitter();

  _mainDrawerContent: MatDrawerContent;
  @ViewChild('matDrawerContent')
  set matDrawerContent(value: MatDrawerContent) {
    this._mainDrawerContent = value;
    if (value) {
      this.registerContent(value, this.nextDrawerPage);
    }
  }
  get mainDrawerContent() {
    return this._mainDrawerContent;
  }

  _mainContent: MatDrawerContent;
  @ViewChild('mainContent')
  set mainContent(content: MatDrawerContent) {
    this._mainContent = content;
    if (content) {
      this.registerContent(content, this.nextPage);
    }
  }
  get mainContent() {
    return this._mainContent;
  }

  registerContent(content: MatDrawerContent, emitter: EventEmitter<any>) {
    content
      .elementScrolled()
      .pipe(
        startWith(10000),
        debounceTime(300),
        map(() => content.measureScrollOffset('bottom')),
        pairwise()
      )
      .subscribe((pairs: any) => {
        const currentV = pairs[1];
        const prevV = pairs[0];
        const scrollOffset = content.measureScrollOffset('bottom');

        console.log(scrollOffset);
        console.log('Current:' + currentV + ' -  Prev:' + prevV);
        if (currentV < prevV || prevV === 0) {
          if (currentV < 300) {
            console.log('next page');
            emitter.emit();
          }
        }
      });
  }

  @Input()
  showAdvancedSearch = true;

  groupDisplayName: string;

  drawerMode: 'over' | 'side' = 'side';
  endDrawerType = ''; // 'PREVIEW' | 'CREATE_EDIT' | 'EDIT' = '';
  drawerOpened = true;
  endDrawerOpened = false;
  currentMediaAlias: string[];
  wasSearchDrawerOpen = true;

  @Output() nextPage = new EventEmitter();

  @ViewChild('matSidebarDrawer')
  matSidebarDrawer: MatDrawer;

  protected _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    protected _fuseMediaWatcherService: FuseMediaWatcherService,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected fleetNavigationService: FleetNavigationService
  ) {}

  ngOnInit(): void {
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((media: any) => {
        this.currentMediaAlias = media.matchingAliases;
        // Set the drawerMode and drawerOpened if
        if (media.matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        } else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }
        this._changeDetectorRef.markForCheck();
      });

    this.fleetNavigationService.currentGroup$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((group: any) => {
        this.groupDisplayName = group ? group.displayName : null;
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  markForCheck() {
    this._changeDetectorRef.markForCheck();
  }

  closeDrawer() {
    this.drawerOpened = false;
    this._changeDetectorRef.markForCheck();
  }

  searchCompleteCheckDrawerForClose() {
    if (this.drawerMode === 'over' && this.drawerOpened === true) {
      this.drawerOpened = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  setEndDrawer(value: boolean, type?: any) {
    this.endDrawerOpened = value;
    this.endDrawerType = type;
    this.wasSearchDrawerOpen = this.drawerOpened;
    if (value) {
      this.drawerOpened = false;
    } else {
      //find out if drawer
      if (this.wasSearchDrawerOpen) {
        this.drawerOpened = this.wasSearchDrawerOpen;
      }
    }

    if (!value) {
      this.drawerClosed.emit();
    }

    this._changeDetectorRef.markForCheck();
  }

  openSearch() {
    this.setEndDrawer(false);

    this.drawerOpened = true;
    this._changeDetectorRef.markForCheck();
  }
}
