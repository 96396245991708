import {
  Component,
  DestroyRef,
  inject,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { NetworkGroupService } from '@fleet/network-group';
import {
  NetworkGroupModel,
  NetworkGroupSearchResultModel,
  NetworkModel,
} from '@fleet/model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  map,
  filter,
  switchMap,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { AuthService } from '@fleet/auth';
import { NetworkApiService, NetworkGroupApiService } from '@fleet/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { combineLatest } from 'rxjs';
import { ProductConfigurationService } from '@fleet/product-configuration';

@Component({
  selector: 'fleet-network-group-switcher',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  template: `
    <div *ngIf="showSelector$ | async" class="flex items-center">
      <div
        [matMenuTriggerFor]="isLocked ? null : networkGroupMenu"
        [class]="
          'min-w-0 px-2 h-10 flex items-center ' +
          (isLocked ? 'cursor-not-allowed' : 'cursor-pointer')
        "
        [matTooltip]="tooltipText"
      >
        <div class="flex items-center">
          <!-- Show network logo if available -->
          <img
            *ngIf="(network$ | async)?.logoUrl"
            [src]="(network$ | async)?.logoUrl"
            class="w-7 h-7 rounded-full mr-2"
            alt="Network logo"
          />

          <!-- Show selected group name or network name -->
          <span class="font-medium truncate">
            {{
              (selectedGroup$ | async)?.displayName ||
                (network$ | async)?.displayName
            }}
          </span>

          <!-- Modified dropdown/lock icon -->
          <mat-icon
            class="ml-1 icon-size-5"
            [svgIcon]="
              isLocked
                ? 'heroicons_solid:lock-closed'
                : 'heroicons_solid:chevron-down'
            "
          ></mat-icon>
        </div>
      </div>

      <!-- Network Group Menu -->
      <mat-menu
        #networkGroupMenu="matMenu"
        [xPosition]="'after'"
        class="network-group-menu"
      >
        <!-- Network option -->
        <button
          mat-menu-item
          *ngIf="network$ | async as network"
          (click)="selectNetwork()"
          [class.selected]="(selectedGroup$ | async) === null"
        >
          <div class="flex items-center">
            <img
              *ngIf="network.logoUrl"
              [src]="network.logoUrl"
              class="w-6 h-6 rounded-full mr-2"
              alt="Network logo"
            />
            <span class="font-medium">{{ network.displayName }}</span>
          </div>
        </button>

        <mat-divider></mat-divider>

        <!-- Group header -->
        <div class="group-header px-4 py-2 text-sm">Network Groups</div>

        <!-- Network groups -->
        <button
          mat-menu-item
          *ngFor="let group of availableGroups$ | async"
          (click)="selectGroup(group)"
          [class.selected]="
            (selectedGroup$ | async)?.networkGroupId === group.networkGroupId
          "
        >
          <div class="flex items-center">
            <img
              *ngIf="group.logoUrl"
              [src]="group.logoUrl"
              class="w-6 h-6 rounded-full mr-2"
              alt="Group logo"
            />
            <span>{{ group.displayName }}</span>
          </div>
        </button>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      ::ng-deep .network-group-menu {
        max-height: 80vh !important;
      }

      ::ng-deep .group-header {
        font-weight: 500;
        color: #666;
        background-color: #f5f5f5;
      }

      ::ng-deep .selected {
        background-color: rgba(0, 0, 0, 0.04);
      }

      button.mat-mdc-button {
        height: 40px;
      }

      .truncate {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button.override-disabled-button[disabled] {
        opacity: 1 !important; /* Remove the faded look */
        background-color: #3f51b5 !important; /* Example background color */
        color: red !important; /* Example text color */
        cursor: pointer !important; /* Make it look clickable */
      }
    `,
  ],
})
export class NetworkGroupSwitcherComponent {
  private network = new BehaviorSubject<NetworkModel>(null);
  private availableGroups = new BehaviorSubject<NetworkGroupModel[]>([]);
  private selectedGroup = new BehaviorSubject<NetworkGroupModel>(null);
  private destroyRef = inject(DestroyRef);

  network$ = this.networkGroupService.network$;
  availableGroups$ = this.availableGroups.asObservable();
  selectedGroup$ = this.selectedGroup.asObservable();
  showSelector$: Observable<boolean>;
  isLocked: boolean;
  tooltipText: string;

  constructor(
    private networkGroupService: NetworkGroupService,
    private authService: AuthService,
    private networkGroupApiService: NetworkGroupApiService,
    private productConfigurationService: ProductConfigurationService,
    private cdr: ChangeDetectorRef
  ) {
    console.log('NetworkGroupSwitcherComponent constructor called');

    //Set up the show selector observable
    this.showSelector$ = combineLatest([
      this.availableGroups$,
      this.productConfigurationService.networkConfig$,
    ]).pipe(
      map(
        ([groups, networkConfig]) =>
          groups &&
          groups.length > 0 &&
          networkConfig &&
          !networkConfig.networkGroupId
      )
    );

    // Subscribe to the network group service's selected group
    this.networkGroupService.networkGroup$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((group) => console.log('Selected group changed:', group)),
        distinctUntilChanged(
          (prev, curr) => prev?.networkGroupId === curr?.networkGroupId
        )
      )
      .subscribe((selectedGroup) => {
        if (selectedGroup) {
          // Find the matching group in available groups
          const availableGroups = this.availableGroups.value;
          const matchingGroup = availableGroups.find(
            (g) => g.networkGroupId === selectedGroup.networkGroupId
          );

          // Update the selected group
          if (matchingGroup) {
            this.selectedGroup.next(matchingGroup);
          } else {
            this.selectedGroup.next(null);
          }
        } else {
          // Set to null (network)
          this.selectedGroup.next(null);
        }
      });

    // Subscribe to auth user changes
    this.authService.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((user) => !!user),
        filter((user) => this.authService.userType === 'NETWORK'),
        switchMap((user) => {
          const networkId = this.authService.networkId;

          // Get network

          return this.networkGroupApiService
            .searchNetworkGroups({}, networkId)
            .pipe(
              map((resp) => {
                const groups = resp.body.data;
                this.availableGroups.next(groups);

                return groups;
              })
            );
        })
      )
      .subscribe();

    this.networkGroupService.attributionEntityAndGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([entity, group]) => {
        this.isLocked = entity !== null;
        this.tooltipText = entity
          ? `Cannot change while viewing ${entity}`
          : '';
      });
  }

  /**
   * Select a network group
   */
  selectGroup(group: NetworkGroupModel): void {
    console.log('Setting network group:', group);

    this.networkGroupService.getNetworkGroup(
      group.networkGroupId,
      group.networkId
    );
  }

  /**
   * Select the network (clear group selection)
   */
  selectNetwork(): void {
    console.log('Clearing network group');
    this.networkGroupService.setSelectedGroup(null);
  }
}
